.Body-Box {
    display: inline-block;
    width: 70%;
    margin: 4vh 0vw;
    vertical-align: top;
}

.Body-Left,
.Body-Right {
    display:inline-block;
}

.Body-Left {
    width: 20vw;
    float: Left;
}

.Body-Right {
    width: 45vw;
    float: right;
}

.Body-Content {
    text-align: left;
}

.Body-Img {
    align-content: center;
    width: 18vw;
}

.color {
    color: #95a5a6;
}

.left-sp {
    margin: 0 0 0 5pt;
}

.min-bot-margin {
    margin-bottom: .1em;
}

.inline-block {
    display: inline-block;
}

h1 {
    font-size: 50px;
}

.signature {
    width: 15vw;
}

.fancy-font {
    font-family: 'Rouge Script', cursive;
    font-size: 30px;
}

@media (max-width: 680px) {
    .Body-Box {
        width: 100%;
        text-align: center;
        margin: auto;
    }

    .Body-Left {
        width: 45vw;
        float: Left;
    }

    .Body-Img {
        align-content: center;
        width: 44vw;
    }
}
