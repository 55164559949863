.Contact{
    width: 100%;
    text-align: center;
    margin: 14vh 0;
}

#contact-form{
    display: inline-block;
    margin: 0 auto;
    padding: 3px;
    width: 70%;
}

.left{
    float: left;
    width: 36%;
    text-align: right;
}

.right{
    float: right;
    width: 63%;
    text-align: left;
}

.form-control{
    width: 50%;
}

.Link-surround-cont-wrks {
    text-decoration: none;
    padding: 6vh 8vh;
    color: black;
    text-align: center;
    position: center;
    font-family: Garamond, serif;
    font-size: 18pt;
}

.Link-cont-wrks {
    text-align: center;
    position: center;
    vertical-align: bottom;
    margin: 6px;
}
