body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow-y: scroll;
}

.w3-flat-turquoise
{color:#fff;background-color:#1abc9c}
.w3-flat-emerald
{color:#fff;background-color:#2ecc71}
.w3-flat-peter-river
{color:#fff;background-color:#3498db}
.w3-flat-amethyst
{color:#fff;background-color:#9b59b6}
.w3-flat-wet-asphalt
{color:#fff;background-color:#34495e}
.w3-flat-green-sea
{color:#fff;background-color:#16a085}
.w3-flat-nephritis
{color:#fff;background-color:#27ae60}
.w3-flat-belize-hole
{color:#fff;background-color:#2980b9}
.w3-flat-wisteria
{color:#fff;background-color:#8e44ad}
.w3-flat-midnight-blue
{color:#fff;background-color:#2c3e50}
.w3-flat-sun-flower
{color:#fff;background-color:#f1c40f}
.w3-flat-carrot
{color:#fff;background-color:#e67e22}
.w3-flat-alizarin
{color:#fff;background-color:#e74c3c}
.w3-flat-orange
{color:#fff;background-color:#f39c12}
.w3-flat-pumpkin
{color:#fff;background-color:#d35400}
.w3-flat-pomegranate
{color:#fff;background-color:#c0392b}
.w3-flat-clouds
{color:#000;background-color:#ecf0f1}
.w3-flat-concrete
{color:#fff;background-color:#95a5a6}
.w3-flat-silver
{color:#000;background-color:#bdc3c7}
.w3-flat-asbestos
{color:#fff;background-color:#7f8c8d}

.loader {
  border: 16px solid #ecf0f1;
  border-radius: 50%;
  border-top: 16px solid #7f8c8d;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {-webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg);}
}

@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
