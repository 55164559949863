.Resume {
    font-family: Garamond, serif;
    font-size: 14pt;
    text-align: left;
    vertical-align: top;
    margin: 14vh 0vw;
}

table {
    width: 70vw;
    margin: auto;
    vertical-align: top;
}

.Resume .Title {
    font-size: 20pt;
    text-align: center;
}

.Resume .Center {
    text-align: center;
}

.Resume .Section-Header {
    display: inline-block;
    position: relative;
    font-weight: bold;
}

.Resume .Section-Info {
    display: inline-block;
    position: relative;
}

.Resume p {
    margin: 5px;
}

@media (max-width: 750px) {
    table {
        width: 95vw;
    }
}

@media (max-width: 470px) {
    .Resume {
        font-size: 10pt;
    }

    .Resume .Title {
        font-size: 14pt;
        text-align: center;
    }
}

.Link-res {
    text-decoration: none;
    padding: 6vh 8vh .25vh 8vh;
    color: black;
}

.btn-res {
    text-align: center;
    position: center;
    font-family: Garamond, serif;
    font-size: 18pt;
    vertical-align: top;
    color: #999999;
}

.res-pdf{
    width: 3vw;
    display:inline-block;
    padding: 1vh;
    margin: 0 1vw;
}

