.Header {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    z-index: 10;
/*    -webkit-box-shadow: 0 7px 8px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0 7px 8px rgba(0, 0, 0, 0.10);
    box-shadow: 0 7px 8px rgba(0, 0, 0, 0.10);*/
}

.Header-Box {
    width: 99%;
    text-align: center;
    margin: auto;
}

.Header-Home,
.Header-Resume,
.Header-Contact,
.Header-Projects{
    display:inline-block;
}

.Header-Left,
.Header-Right {
    display:inline-block;
}

.Header-Left {
    float: Left;
}

.Header-Right {
    float: right;
}

.Link {
    text-decoration: none;
    padding: 3vh 3vw;
    color: black;
}

.logo {
    height: 8vh;
    padding: 1vh 1vw;
}

@media (max-width: 560px) {

    .Header-Box {
        width: 100%;
        text-align: center;
        margin: auto;
    }

    .Link {
        text-decoration: none;
        padding: 1vh 1vw;
        color: black;
    }
}
