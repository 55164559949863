.projects{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 70vw;
    margin-top: 10vh;
    margin-left: auto;
    margin-right: auto;
}

.loadStyle{
    display: inline-block;
    text-align: center;
    margin: 10vh 0;
}
